<template>
    <div class="page-content">
    <el-form ref="form" :model="form" label-width="100px" >
        <el-form-item label="充值店铺提成">
            <el-input placeholder="请输入内容"v-model="form.store_price">
                <template slot="append">%</template>
            </el-input>
        </el-form-item>
        <el-form-item label="平台订单提成">
            <el-input placeholder="请输入内容"v-model="form.platform_price">
                <template slot="append">%</template>
            </el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="saveCommission">保存修改</el-button>

        </el-form-item>
    </el-form>
    </div>
</template>
<script>
    import RichText from './../../components/RichText'
    export default {
        data() {
            return {
                form: {
                    store_price: '',
                    platform_price: '',

                }
            }
        },
        components: {
            RichText
        },
        created(){
            this.commission();
        },
        methods: {

            //获取金额设置
            async commission(){
                let [e, data] = await this.$api.commission();

                if(e) return;
                if(data.code === 200){
                    this.form = data.data;
                }
            },
            //编辑金额设置
            async saveCommission(){
                let [e, data] = await this.$api.saveCommission(this.form);
                if(e) return;
                if (data.code === 200) {
                    this.$message.success(data.msg)
                    this.commission();
                }
            }

        }
    }
</script>
<style>



</style>
